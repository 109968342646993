<template>
  <div>
    <v-row class="my-4 ml-1">
      <v-col cols="12" lg="6" xl="6" md="6" sm="12">
        <v-row class="caption grey--text text--darken-1">{{
          $t("excavationAreaOnProperty")
        }}</v-row>
        <v-row class="grey--text text--darken-4 font-weight-medium body-2">
          {{ excavationDetails.location }}
        </v-row>
      </v-col>
      <v-col
        cols="12"
        lg="6"
        xl="6"
        md="6"
        sm="12"
        :class="$vuetify.breakpoint.smAndDown ? 'mt-4' : ''"
      >
        <v-row class="caption grey--text text--darken-1">{{
          $t("typeOfWork")
        }}</v-row>
        <v-row class="grey--text text--darken-4 font-weight-medium body-2">
          {{ excavationDetails.work_type }}
        </v-row>
      </v-col>
    </v-row>
    <v-row class="my-4 ml-1">
      <v-col>
        <v-row class="caption grey--text text--darken-1">{{
          $t("locatorInstructions")
        }}</v-row>
        <v-row
          v-for="item in excavationDetails.remarks"
          :key="item"
          class="grey--text text--darken-4 font-weight-medium body-2"
        >
          <div>
            {{ item }}
          </div>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="my-4 ml-1">
      <v-col>
        <v-row class="caption grey--text text--darken-1">{{
          $t("vupsPremarksLabel")
        }}</v-row>
        <v-row class="grey--text text--darken-4 font-weight-medium body-2">
          {{ excavationDetails.white_paint ? "Yes" : "No" }}
        </v-row>
      </v-col>
    </v-row>
    <v-row class="my-4 ml-1">
      <v-col>
        <v-row class="caption grey--text text--darken-1">{{
          $t("vupsBoringLabel")
        }}</v-row>
        <v-row class="grey--text text--darken-4 font-weight-medium body-2">
          {{ excavationDetails.boring ? "Yes" : "No" }}
        </v-row>
      </v-col>
    </v-row>
    <v-row class="my-4 ml-1">
      <v-col>
        <v-row class="caption grey--text text--darken-1">{{
          $t("vupsBlastingLabel")
        }}</v-row>
        <v-row class="grey--text text--darken-4 font-weight-medium body-2">
          {{ excavationDetails.blasting ? "Yes" : "No" }}
        </v-row>
      </v-col>
    </v-row>
    <v-row class="my-4 ml-1">
      <v-col>
        <v-row class="caption grey--text text--darken-1">{{
          $t("additionalComments")
        }}</v-row>
        <v-row class="grey--text text--darken-4 font-weight-medium body-2">
          {{ excavationDetails.comments }}
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: {
    excavationDetails: {
      type: Object,
      default: () => {},
    },
  },
  created() {
    this.moment = moment;
  },
};
</script>

<style lang="scss" scoped></style>
